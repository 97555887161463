import { useEffect, useLayoutEffect } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import useLastSafe from '@/hooks/useLastSafe'
import { AppRoutes } from '@/config/routes'

const useIsomorphicEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const IndexPage: NextPage = () => {
  const router = useRouter()
  const { wallet, chain } = router.query
  const lastSafe = useLastSafe()
  const safeAddress = wallet || lastSafe

  useIsomorphicEffect(() => {
    if (router.pathname !== AppRoutes.index) {
      return
    }

    // router.replace(
    //   safeAddress
    //     ? `${AppRoutes.home}?wallet=${safeAddress}`
    //     : chain
    //     ? `${AppRoutes.welcome.index}?chain=${chain}`
    //     : AppRoutes.welcome.index,
    // )

    router.replace(AppRoutes.welcome.index)
  }, [router])

  return <></>
}

export default IndexPage
